<template>
  <el-cascader v-model="value" :options="options" />
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import type { District } from './types'
import districts from './districts.json'

// 格式化原始数据
function formatDistricts(districts: District[]): District[] {
  return districts.map(dist => {
    if (dist.children) {
      dist.children = formatDistricts(dist.children)
    }
    dist.value = dist.label
    return dist
  })
}

// 将字符串地址转换为数组
// 如：浙江省杭州市拱墅区 => ['浙江省', '杭州市', '拱墅区']
function splitAddress(address: string, dists: District[] = districts): string[] {
  address = address.trim()
  let res: string[] = []
  dists.forEach(d => {
    if (address.indexOf(d.label) === 0) {
      res.push(d.label)
      if (d.children) {
        const restAddress = address.substr(d.label.length)
        const c = splitAddress(restAddress, d.children)
        if (c.length > 0) {
          res = res.concat(c)
        }
      }
    }
  })
  return res
}

// const provinceLevelCities = ['北京市', '上海市', '天津市', '重庆市']

// 直辖市加上重复的部分
// 如：上海市崇明区 => 上海市上海市崇明区
// function repeatProvinceLevelCity(address: string[]): string[] {
//   if (address.length > 1) {
//     for (let i = 0; i < provinceLevelCities.length; i++) {
//       const item = provinceLevelCities[i]
//       console.log('repeatProvinceLevelCity', address)
//       if (address[0] === item && address[1] !== item) {
//         console.log('repeat', address)
//         address.unshift(address[0])
//       }
//     }
//   }
//   return address
// }

// 将重复的直辖市过滤
// 如：上海市上海市崇明区 => 上海市崇明区
// function trimRepetitionDist(address: string): string {
//   for (let i = 0; i < provinceLevelCities.length; i++) {
//     const item = provinceLevelCities[i]
//     if (address.indexOf(item + item) === 0) {
//       address = address.substr(item.length)
//     }
//   }
//   return address
// }

export default defineComponent({
  name: 'DistrictSelect',
  props: {
    modelValue: String as PropType<string>
  },
  setup(props, { emit }) {
    const options: District[] = formatDistricts(districts)
    const value = computed({
      get: () => splitAddress(props.modelValue || ''),
      set: (value) => {
        emit('update:modelValue', value.join(''))
      }
    })
    return {
      value,
      options
    }
  }
})
</script>
